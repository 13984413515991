/*
 * @Author: 秦龙(004894)
 * @Date: 2021-12-27 15:08:53
 * @LastEditTime: 2021-12-27 15:21:01
 * @LastEditors: 秦龙(004894)
 * @Description:
 * @FilePath: \i18n-saas-fcbox-admin-frontend\src\filter\placement.js
 */
export default function(val) {
  if (typeof val === 'string' && val.trim() === '') {
    return '-'
  } else if (typeof val === 'undefined') {
    return '-'
  } else if (typeof val === 'object' && val === null) {
    return '-'
  } else {
    return val
  }
}

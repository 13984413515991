<!--
 * @Author: 秦龙(004894)
 * @Date: 2023-07-18 14:40:20
 * @LastEditTime: 2023-07-25 11:42:22
 * @LastEditors: 秦龙(004894)
 * @Description: 数据脱敏展示，点击切换完整数据
 * @FilePath: /i18n-saas-iot-frontend/src/components/CheckSecret.vue
-->
<template>
  <el-link v-if="secret" type="primary" :icon="secretIcon" @click="toggleSecret">
    <template v-if="!secretCheck">{{ prefix }}{{ secret | placement }}{{ suffix }}</template>
    <template v-else>{{ prefix }}{{ fieldValue | placement }}{{ suffix }}</template>
  </el-link>
  <span v-else>-</span>
</template>

<script>
import { axios1 } from '../api/instance'
import placement from '@/utils/placement.js'

const fieldEnums = {
  userPhone: { url: '/user/phone', method: 'get' }
}
export default {
  filters: { placement },
  props: {
    // 展示数据
    secret: {
      type: String,
      default: '',
      required: true,
    },
    // fieldKey，用于请求明文数据
    fieldKey: {
      default: '',
    },
    // fieldType，用于请求明文数据类型
    fieldType: {
      type: String,
      default: '',
    },
    // 前缀，原样输出，不格式化
    prefix: {
      type: String,
      default: '',
    },
    // 后缀，原样输出，不格式化
    suffix: {
      type: String,
      default: '',
    },
    // 是否立即显示完整数据
    immediate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      secretCheck: false,
      secretIcon: 'el-icon-view',
      fieldValue: '',
    }
  },
  computed: {
    unique() {
      return JSON.stringify(this.fieldKey) + JSON.stringify(this.fieldType) + JSON.stringify(this.secret)
    },
  },
  watch: {
    unique() {
      this.secretCheck = false
      this.secretIcon = 'el-icon-view'
      if (this.immediate) {
        this.toggleSecret()
      }
    },
  },
  methods: {
    /**
     * @description: 获取非脱敏的完整数据
     */
    toggleSecret() {
      if (!this.secretCheck) {
        if (this.secretIcon === 'el-icon-loading' || !this.fieldKey || !this.fieldType) return
        this.secretIcon = 'el-icon-loading'
        const target = fieldEnums?.[this.fieldType]
        axios1.request({
          methods: [target?.method],
          url: target?.url,
          [target?.method === 'get' ? 'params' : 'data']: this.fieldKey,
        })
          .then((res) => {
            const isEmail = /.+@.+\..+/.test(res.data)
            this.secretIcon = isEmail ? 'el-icon-message' : 'el-icon-mobile-phone'
            this.fieldValue = typeof res.data === 'object' ? '' : res.data
          })
          .catch(() => {
            this.secretIcon = 'el-icon-view'
          })
          .finally(() => {
            this.secretCheck = true
          })
      } else {
        this.secretIcon = 'el-icon-view'
        this.secretCheck = false
      }
    },
  },
}
</script>

<template>
  <div class="user-manage-wrap">
    <el-form
      ref="form"
      size="small"
      class="search-pannel"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="姓名：">
        <el-input v-model="searchForm.searchUserName" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="searchForm.searchPhone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="角色：">
        <Select clearable v-model="searchForm.searchRoleId" :list="searchRoleIdList"> </Select>
      </el-form-item>
      <el-form-item label="状态：">
        <Select clearable placeholder="请选择状态" v-model="searchForm.searchStatus" :list="searchStatusList"> </Select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="small" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button size="small" @click="onReset('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- <el-row class="row-list">
      <el-button type="success" size="small" @click="newlyAdded('add')">新增</el-button>
    </el-row> -->
    <Table
      class="row-list"
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @pagination="updatePage"
    >
      <template #email="{ originScope }">
        <span class="g-single-overflow">{{ originScope.row.email }}</span>
      </template>
      <template #status="{ originScope }">
        <Point slot="pickupStatusText" :color="originScope.row.status === 0 ? '#A2A3A5' : '#85CF26'">{{
          originScope.row.status === 0 ? '禁用中' : '启用中'
        }}</Point>
      </template>
      <template #phone="{ originScope}">
        <CheckSecret :secret="originScope.row.phone" fieldType="userPhone" :fieldKey="{ uicId: originScope.row.uicId}" />
      </template>
      <el-table-column slot="operate" label="操作" width="150" fixed="right">
        <template #default="scope">
          <el-button style="color: #77b922" type="text" size="small" @click="handleClickEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            style="color: #77b922"
            v-if="scope.row.status === 0"
            type="text"
            size="small"
            @click="handleClickEnable(scope.row)"
            >启用</el-button
          >
          <el-button style="color: #77b922" v-else type="text" size="small" @click="handleClickDisable(scope.row)"
            >禁用</el-button
          >
        </template>
      </el-table-column>
    </Table>
    <Dialog
      width="576px"
      :title="dlogstatus == 'add' ? '新增用户' : '编辑用户'"
      :visible="visible"
      @close="closeDialog"
    >
      <div class="newly-add-wrap">
        <el-form
          size="small"
          ref="ruleForm"
          class="newly-form"
          label-width="100px"
          label-position="left"
          :rules="rules"
          :model="form"
        >
          <el-form-item label="姓名：" prop="userName">
            <el-input :disabled="dlogstatus === 'edit'" v-model="form.userName"></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input :disabled="dlogstatus === 'edit'" v-model="form.phone"></el-input>
            <p>初始密码：FCSaaS2021--</p>
          </el-form-item>
          <el-form-item label="角色：" prop="roleIds">
            <Select multiple placeholder="请选择角色" v-model="form.roleIds" :list="addStatusList"> </Select>
          </el-form-item>
          <el-form-item label="邮箱：" prop="mail">
            <el-input v-model="form.mail"></el-input>
          </el-form-item>
        </el-form>
        <el-row type="flex" justify="end">
          <el-button @click="visible = false" size="small">取 消</el-button>
          <el-button type="success" @click="submitCreatedUser" size="small">确定</el-button>
        </el-row>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
import Dialog from '@/components/Dialog'
import Point from '@/components/Point'
import Select from '@/components/Select'
import { dateFormatter } from '@/utils'
import CheckSecret from '@/components/CheckSecret.vue'

import {
  searchUser,
  createUser,
  searchUserList,
  enabledUser,
  disableUser,
  updateUser,
  addUserRepeat
} from '@/api/modules/system'
export default {
  name: 'userManage',
  components: {
    Table,
    Dialog,
    Point,
    Select,
    CheckSecret
  },
  data() {
    return {
      visible: false,
      tableLoading: false,
      dlogstatus: '',
      searchForm: {
        searchUserName: '',
        searchPhone: '',
        searchRoleId: '',
        searchStatus: ''
      },
      columns: [
        { label: '姓名', prop: 'userName' },
        { label: '手机号', prop: 'phone' },
        { label: '邮箱', prop: 'email' },
        { label: '角色', prop: 'roleNames' },
        { label: '状态', prop: 'status' },
        { label: '创建人', prop: 'createUserName' },
        { label: '创建时间', prop: 'createSysTm' }
      ],
      tableData: [], // status 0 禁用， 1 启用
      form: {
        roleIds: [],
        userName: '',
        phone: '',
        mail: ''
      },
      searchRoleIdList: [],
      searchStatusList: [
        { label: '禁用', value: 0 },
        { label: '启用', value: 1 }
      ],
      addStatusList: [],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      uicId: '',
      recursionNum: 0 // 防止函数死递归
    }
  },
  created() {
    this.onSearch(true)
    this.getStatusUserList()
  },
  computed: {
    rules() {
      var confirmPhoneRule = (rule, value, callback) => {
        const rules = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (!rules.test(value)) {
          callback(new Error('请输入正确的手机号码'))
        }
        callback()
      }
      return {
        roleIds: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: confirmPhoneRule, trigger: 'blur' }
        ],
        mail: [
          { required: true, message: '请输入邮箱', trigger: 'chang' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    // 获取table列表
    onSearch(flag) {
      const data = this.getParams(flag)
      this.tableLoading = true
      searchUser(data)
        .then((res) => {
          const { records, pageNum, pageSize, total } = res.data
          this.tableData = records
          this.tableData.forEach((item) => {
            item.createSysTm = dateFormatter(new Date(item.createSysTm))
          })
          this.pageParams = { pageNum, pageSize, total }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    // 获取状态列表
    getStatusUserList() {
      searchUserList({ pageNum: 1, pageSize: 100 }).then((res) => {
        const data = res.data.records
        this.searchRoleIdList = data || []
        this.searchRoleIdList.forEach((item) => {
          item.value = item.roleId
          item.label = item.roleName
        })
        this.addStatusList = data || []
      })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.searchForm = {
        searchUserName: '',
        searchPhone: '',
        searchRoleId: '',
        searchStatus: ''
      }
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    // 新增
    newlyAdded(type) {
      console.log('newlyAdded-userManage: ')
      this.dlogstatus = type
      this.visible = true
      !this.addStatusList.length && this.getStatusUserList()
    },
    // 弹窗隐藏回调
    closeDialog() {
      this.visible = false
      this.$refs.ruleForm.resetFields()
      this.form = {
        roleIds: [],
        userName: '',
        phone: '',
        mail: ''
      }
    },
    // 提交新增&编辑验证
    submitCreatedUser() {
      const data = this.form
      this.$refs.ruleForm.validate(async(valid) => {
        if (!valid) return
        if (this.dlogstatus === 'add') {
          this.handleaddExit(createUser, data)
        } else {
          this.handleaddExit(updateUser, { ...data, uicId: this.uicId })
        }
      })
    },
    // 处理新增&编辑接口
    handleaddExit(fn, data) {
      fn(data).then((res) => {
        if (res.code === '000000000') {
          this.$message.success(res.msg)
          this.visible = false
          this.recursionNum = 0
          this.dlogstatus === 'add' ? this.onSearch(true) : this.onSearch(false)
        } else if (res.code === '103050002') {
          const addFlag = this.dlogstatus === 'add'
          addFlag &&
            this.recursionNum <= 1 &&
            this.$confirm(`${this.form.phone}已存在，是否授权此用户使用？`)
              .then(() => {
                const adddata = this.form
                this.recursionNum++
                this.handleaddExit(addUserRepeat, adddata)
              })
              .catch(() => {})
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 编辑
    handleClickEdit(row) {
      this.visible = true
      !this.addStatusList.length && this.getStatusUserList()
      this.dlogstatus = 'edit'
      this.form.mail = row.email
      this.form.roleIds = row.roleIds || []
      this.form.phone = row.phone
      this.form.userName = row.userName
      this.uicId = row.uicId
    },
    // 禁用
    handleClickDisable(row) {
      this.$confirm('确认要禁用选中的账号吗？', '确认')
        .then(async() => {
          const result = await disableUser({ uicId: row.uicId })
          if (result.code === '000000000') {
            this.$message.success(result.msg)
            this.onSearch(false)
          } else {
            this.$message.warning(result.msg)
          }
        })
        .catch()
    },
    // 启用
    handleClickEnable(row) {
      this.$confirm('确认要启用选中的账号吗？', '确认')
        .then(async() => {
          const result = await enabledUser({ uicId: row.uicId })
          if (result.code === '000000000') {
            this.$message.success(result.msg)
            this.onSearch(false)
          } else {
            this.$message.warning(result.msg)
          }
        })
        .catch()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-manage-wrap {
  padding: 24px;
}
.row-list {
  margin: 32px 0 12px 0;
}
// 创建用户弹窗
.newly-add-wrap {
  .newly-form {
    padding: 0 121px 0 27px;
    p {
      font-size: 12px;
      line-height: 17px;
      color: #a2a3a5;
      margin-top: 6px;
    }
    ::v-deep .el-form-item--small {
      margin-bottom: 20px;
    }
  }
}

::v-deep .el-tag.el-tag--info {
  color: #85cf26;
  background: #fbfff0;
}
::v-deep .el-tag.el-tag--info .el-tag__close {
  color: #ffffff;
  background: #85cf26;
  margin-top: 1px;
}
</style>
